// AWS Configuration
window.awsConfig = {
    region: window.APP_CONFIG?.AWS_REGION || 'us-west-2',
    userPoolId: window.APP_CONFIG?.USER_POOL_ID || 'us-west-2_elgiPTht4',
    userPoolWebClientId: window.APP_CONFIG?.CLIENT_ID || '4lp68ka85qlei2d5hv190pdojm',
    authenticationFlowType: 'USER_SRP_AUTH',
    cookieStorage: {
        domain: window.location.hostname,
        path: '/',
        expires: 365,
        secure: true,
        sameSite: 'strict'
    },
    // Auth token configuration
    tokenValidityInMinutes: {
        accessToken: 60,          // 1 hour
        idToken: 60,             // 1 hour
        refreshToken: 43200      // 30 days
    }
};